import React from 'react'
import { HeaderPage } from '../../components/header'
import { SideMenu } from '../../components/side-menu/side-menu'
import { DashboardRoutes } from '../../routes/dashboard-routes'
import { useParams } from 'react-router-dom'

export const Dashboard = () => {
    const params = useParams();
    return (
        <div className='container-fluid mobile_bg_primary'>
            <div className='border-top border-dark border-xl-bottom border-dark pb-5 pb-sm-0'>
                <div className='mobile_dashboard'>
                    <div className='row'>
                        <div className='col-4 col-md-2 border-end border-dark'>
                            <SideMenu />
                        </div>
                        <div className='col-8 col-md-10 '>
                            <DashboardRoutes />
                        </div>
                        
                    </div>
                    <div className='row'>
                        <div className='col-md-12 fs-3 py-2 text-center fw-bold'>Need Assistance? <a className='text-decoration-none fw-bold cursor-pointer'>Click here.</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
