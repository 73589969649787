import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { GET_USER_ORDER } from '../../../app/services/user-orders.service';
import { Loader } from '../../../components/loader';
import moment from 'moment';
import { OrdersData, OrdersResponse } from '../../../app/model/orders';
import toast from 'react-hot-toast';

export const MyOrders = () => {
    const params = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [userOrders, setUserOrders] = useState<any[]>([]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getUserVehicleInfo();
    }, [])

    const getUserVehicleInfo = () => {
        setLoading(true);
        GET_USER_ORDER({ user_uuid: params?.user_uuid ? params?.user_uuid : '', order_status: 0 }).then((res: OrdersResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    setUserOrders([...res?.data?.data]);
                    setLoading(false);
                }
            }
        });
    }
    return (
        <div className='container'>
            <div className='row my-3'>
                <Loader LoadingReducer={loading} />
                <div className='col-md-12 px-lg-5 mx-auto'>
                    <h4 className='fw-bold font-Oswald my-3 fs-40 ls-1 mb-4'>MY ORDERS</h4>
                    <div className='table-responsive'>
                        <table className='table table-bordered border-dark border-1'>
                            <tbody>
                                <tr>
                                    <th>Registration Renewal</th>
                                    <th>Order ID</th>
                                    <th>Order Date</th>
                                    {/* <th>Tag</th> */}
                                    <th>Order Status</th>
                                    <th>Shipping Status</th>
                                    <th>Payment Status</th>
                                </tr>
                            </tbody>
                            <tbody>
                                {userOrders?.map((data: OrdersData, index: number) => {
                                    return <tr key={index}>
                                        <td>{data?.service_type?.name}</td>
                                        <td>{data?.order_id}</td>
                                        <td>{moment(data?.created_at).format('DD-MM-YYYY')}</td>
                                        {/* <td>{data?.tag}</td> */}
                                        <td>{data?.order_status === 0 ? 'PENDING' : 'COMPLETED'}</td>
                                        <td>{data?.shipping_status}</td>
                                        <td>{data?.payment_status}</td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className='col-md-12'>
                        <h5 className='fw-bold my-4'>eTag Digital Copy</h5>
                        <div className='my-2 fs-18 lh-sm fw-600'>Your temporary proof of vehicle registration will be available here.</div>
                        <div className='my-2 fs-18 lh-sm fw-600'>Your registration will be shipped to:</div>
                    </div>
                    {userOrders?.length > 0 && <div className='col-md-12 mt-5'>
                        <h5 className='fw-bold my-3'>NAME</h5>
                        <div className='text-start fs-18 fw-600'>{`${userOrders[userOrders?.length - 1]?.user_shipping_address?.first_name} ${userOrders[userOrders?.length - 1]?.user_shipping_address?.last_name} `}</div>
                        <div className='text-start fs-18 fw-600'>{`${userOrders[userOrders?.length - 1]?.user_shipping_address?.street_address}, ${userOrders[userOrders?.length - 1]?.user_shipping_address?.city}`}</div>
                        <div className='text-start fs-18 mb-4 fw-600'>{`${userOrders[userOrders?.length - 1]?.user_shipping_address?.state}`}, {`${userOrders[userOrders?.length - 1]?.user_shipping_address?.zip_code}`}</div>
                    </div>}
                </div>
            </div>
        </div>
    )
}
