import React from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "../pages/homepage";
import { VehicleRegistration } from "../pages/registration-page/registration-page";
import { HeaderPage } from "../components/header";
import { Footer } from "../components/footer";
import { DmvRecordsCheck } from "../pages/dmv-records-check/dmv-records-check";
import { ShippingAddress } from "../pages/shipping-address/shipping-address";
import { AddressConfirmation } from "../pages/address-confirmation/address-confirmation";
import { DmvSearchFinalized } from "../pages/dmv-search-finalized/dmv-search-finalized";
import { AddAnotherVehicle } from "../pages/add-another-vehicle/add-another-vehicle";
import { PaymentDetails } from "../pages/payment-details-page/payment-details";
import { OrderStatus } from "../pages/order-status/order-status";
import { PrivacyPolicy } from "../pages/privacy-policy";
import { AddMultipleVehicles } from "../pages/add-multiple-vehicles/add-multiple-vehicles";
import { PaymentInformation } from "../pages/payment-information/payment-information";
import { ThankYouAndInsuranceOffers } from "../pages/thank-you-and-insurance-offers/thank-you-and-insurance-offers";
import { LogIn } from "../pages/log-in/login";
import { ThankYou } from "../pages/thank-you/thank-you";
import { MyOrders } from "../pages/orders-dashboard/my-orders/my-orders";
import { MyGarage } from "../pages/orders-dashboard/my-garage/my-garage";
import { OurServices } from "../pages/orders-dashboard/our-services/our-services";
import { Dashboard } from "../pages/orders-dashboard/dashboard";
import { Congratulations } from "../pages/congratulations/congratulations";

export const RootNavigationPage = () => {
  return (
    <>
      {/* <BrowserRouter basename="/simple-tags"> */}
      <HashRouter>
        <HeaderPage />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/vehicle-registration" element={<VehicleRegistration />} />
          <Route path="/checking-dmv-records/:user_uuid/:vehicle_uuid" element={<DmvRecordsCheck />} />
          <Route path="/shipping-address/:user_uuid/:vehicle_uuid" element={<ShippingAddress />} />
          <Route path="/address-confirmation/:user_uuid/:vehicle_uuid/:address_uuid" element={<AddressConfirmation />} />
          <Route path="/dmv-search-finalized" element={<DmvSearchFinalized />} />
          <Route path="/add-another-vehicle/:user_uuid/:address_uuid" element={<AddAnotherVehicle />} />
          <Route path="/add-multiple-vehicle" element={<AddMultipleVehicles />} />
          <Route path="/payment-details/:user_uuid/:cart_uuid/:address_uuid" element={<PaymentDetails />} />
          <Route path="/payment-information" element={<PaymentInformation />} />
          <Route path="/order-status/:user_uuid/:cart_uuid" element={<OrderStatus />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/congratulations" element={<Congratulations />} />
          <Route path="/thank-you/:user_uuid/:cart_uuid/:address_uuid" element={<ThankYouAndInsuranceOffers />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/thanks-you" element={<ThankYou />} />
          <Route path="/my-orders" element={<MyOrders />} />
          <Route path="/my-garage" element={<MyGarage />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/dashboard/*" element={<Dashboard />}></Route>
        </Routes>
        <Footer />
      </HashRouter>
      {/* </BrowserRouter> */}
    </>
  );
};
