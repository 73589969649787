import React, { useEffect, useState } from 'react'
import DMV from './../../assets/images/DMV_Logo.png';
import Car from './../../assets/images/SimpleTags_icons_car.png';
import Bike from './../../assets/images/SimpleTags_icons_motorcycle.png';
import Ship from './../../assets/images/Simple Tags_icons_boat.png';
import Trolly from './../../assets/images/SimpleTags_icons_trailer.png';
import Truck from './../../assets/images/SimpleTags_icons_commercial truck.png';
import { NavLink } from 'react-router-dom'
import { CustomMultiSelect } from '../common-components/custom-drop-down';
import { CommonStaticData } from '../../app/model/common';
import { GET_SYSTEM_PARAMS } from '../../app/services/system-params.service';

export const VehicleRegistrationForm = () => {
    const [DMVDetails, setDMVDetails] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const serviceTypes = [
        {
            label: "Renew Vehicle Registration",
            value: "Renew Vehicle Registration"
        },
        {
            label: "Replace Vehicle Registration Sticker/Card",
            value: "Replace Vehicle Registration Sticker/Card"
        },
        {
            label: "Transfer Title",
            value: "Transfer Title"
        },
        {
            label: "Replace Title",
            value: "Replace Title"
        },
        {
            label: "Boat - Renew Registration",
            value: "Boat - Renew Registration"
        },
        {
            label: "Boat - Replace Registration Sticker/Card",
            value: "Boat - Replace Registration Sticker/Card"
        },
        {
            label: "Boat - Transfer Title",
            value: "Boat - Transfer Title"
        },
        {
            label: "Boat - Replace Title",
            value: "Boat - Replace Title"
        },
        {
            label: "Check Registration Status",
            value: "Check Registration Status"
        },
    ]

    const vehicleImages: CommonStaticData[] = [
        {
            title: "Car",
            image: Car
        },
        {
            title: "Bike",
            image: Bike
        },
        {
            title: "Ship",
            image: Ship
        },
        {
            title: "Trolly",
            image: Trolly
        },
        {
            title: "Truck",
            image: Truck
        },
    ]

    const [selectedData, setSelectedData] = useState<string[]>([]);

    const handleSelectionChange = (selectedOptions: string[]) => {
        setSelectedData(selectedOptions);
    };

    useEffect(() => {
        getSystemParams();
    }, [])

    const getSystemParams = () => {
        GET_SYSTEM_PARAMS({ code: 'DMV_LOGO' }).then((res: any) => {
            if (res?.status === "fail") {
                setLoading(false);
            } else {
                if (res) {
                    if (res?.data?.data?.length > 0) {
                        setDMVDetails(res?.data?.data[0]);
                    }
                    setLoading(false);
                }
            }
        });
    }

    return (
        <div className='col-md-12 primary-bg px-3 px-md-5 py-3'>
            <h5 className='fw-bold text-center font-proxima-nova'>Select a Service for Vehicle Registration or Title</h5>
            <div className='row mx-auto'>
                <div className='col-12 d-block d-sm-none d-flex justify-content-between mx-auto'>
                    <div className='d-flex justify-content-center'>
                        {vehicleImages?.map((vehicle: CommonStaticData, index: number) => (
                            <div className='' key={index}>
                                <img src={vehicle?.image} className='vehicle-image' />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='col-sm-10 col-lg-8 border-0 py-3 mb-lg-4 px-lg-3 bg-white rounded-4 ms-md-auto'>
                    <h5 className='fw-bold text-center font-proxima-nova d-none d-lg-block ms-xl-5 ps-xl-5'>GET STARTED NOW!</h5>
                    <div className='row ms-lg-5 ps-lg-5 mx-md-auto mx-lg-0 px-auto d-flex justify-content-center'>
                        <div className='col-12 col-md-10 col-xl-10 px-xl-2 my-2 py-md-0 my-lg-3 my-xl-2 mx-lg-4 mx-xl-0'>
                            {/* <select className='form-select '>
                                <option value="">Select Service Type</option>
                                {serviceTypes?.map((service: any, i: number) => (
                                    <option value={service.value} key={i} >{service?.label}</option>
                                ))}
                            </select> */}
                            <CustomMultiSelect optionsList={serviceTypes} customPlaceholder={"Select Service Type"}
                                onSelectionChange={handleSelectionChange} />
                        </div>
                        <div className='col-md-10 col-xl-5 my-2 my-lg-3'>
                            <input type="text" className='form-control ' placeholder='License Plate Number' />
                        </div>
                        <div className='col-10 col-md-8 col-xl-4 my-2 my-lg-3'>
                            <input type="text" className='form-control ' placeholder='Last 3 of VIN' />
                        </div>
                        <div className='col-2 col-md-2 col-xl-1 mt-2 py-2 pt-md-1 ms-md-2 ps-md-2'>
                            <i className="bi bi-question-circle-fill fs-4 mb-3 position-relative"></i>
                        </div>
                        <div className='col-md-10 col-xl-5  my-2 my-lg-3'>
                            <input type="text" className='form-control' placeholder='First Name' />
                        </div>
                        <div className='col-md-10 col-xl-5  my-2 my-lg-3'>
                            <input type="text" className='form-control' placeholder='Last Name' />
                        </div>
                        <div className='col-md-10 col-xl-10  my-2 my-lg-3'>
                            <input type="text" className='form-control' placeholder='Email Address' />
                        </div>
                        <div className='col-md-10 col-xl-10 px-xl-0 my-lg-3 my-xl-0 text-end text-xl-center ms-0 ms-xl-3 mt-2 mt-md-0 mb-md-3 mb-xl-0'>
                            <NavLink to="/checking-dmv-records" className="text-decoration-none"><button className='px-3 fs-5 rounded border-0 primary-bg primary-button fw-bold'>LOCATE VEHICLE</button></NavLink>
                        </div>
                    </div>
                    <div className='row ms-lg-5 ps-lg-5'>
                        <div className='col-12 d-md-flex justify-content-start'>
                            <div className='mx-auto mx-sm-0 float-center d-flex justify-content-center my-2'>
                                <img src={DMVDetails?.value?.dmv_logo || DMV} alt="dmv" className='dmv-image' />
                            </div>
                            <div className='align-items-center mt-2'>
                                <h4 className='ms-md-5 secondary-color text-center text-md-start font-Oswald'>{DMVDetails?.value?.title ? DMVDetails?.value?.title : 'DMV Delegated Provider'}</h4>
                                <div className='ms-md-4 secondary-color text-md-start font-Oswald'>{DMVDetails?.value?.sub_title ? DMVDetails?.value?.sub_title : 'Officially Entrusted by the CA Department of Motor Vehicles'}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-sm-1 d-none d-sm-block d-flex justify-content-start me-auto'>
                    <div className='d-flex flex-column justify-content-between my-5 pt-4'>
                        {vehicleImages?.map((vehicle: CommonStaticData, index: number) => (
                            <div className='px-0' key={index}>
                                <img src={vehicle?.image} className='vehicle-image' />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
