import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { DVMDetails } from '../../app/model/dvm-details';
import { useParams } from 'react-router-dom';

interface Props {
    multipleOrders?: any;
    UserDataReducer: DVMDetails[];
    vehicleData?: DVMDetails[];
    isUserCart?: boolean;
}

const DataTable = (props: Props) => {
    const params = useParams()
    const [loading, setLoading] = useState<boolean>(false);
    const [tableData, settableData] = useState<DVMDetails[]>([]);

    useEffect(() => {
        if (props?.isUserCart) {
            const data: DVMDetails[] = [];
            if (props?.vehicleData) {
                props?.vehicleData.forEach((element: any) => {
                    data.push(element?.user_vehicle)
                });
                settableData([...data]);
            }
        } else {
            settableData(props?.vehicleData || []);
        }
    }, [props?.vehicleData])

    return (
        <div className='row'>
            <div className='col-md-12 table-responsive'>
                <table className="table  table-bordered border-dark">
                    <thead>
                        <tr>
                            <th scope="col">Vehicle Type</th>
                            <th scope="col">Vehicle Make</th>
                            <th scope="col">License Plate</th>
                            <th scope="col text-wrap">VIN Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((data: DVMDetails, index: number) => {
                            return <tr key={index}>
                                <td>{data?.vehicle_type}</td>
                                <td>{data?.vehicle_make}</td>
                                <td>{data?.vehicle_license_plate}</td>
                                <td className='text-wrap'>{data?.vehicle_vin_number}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        UserDataReducer: state.UserDataReducer,
    };
};

const connectedNavBar = connect(mapStateToProps)(DataTable);
export { connectedNavBar as DataTable };