import React, { useEffect, useState } from 'react'
import DMV from './../../assets/images/DMV_Logo.png';
import Car from './../../assets/images/SimpleTags_icons_car.png';
import Bike from './../../assets/images/SimpleTags_icons_motorcycle.png';
import Ship from './../../assets/images/Simple Tags_icons_boat.png';
import Trolly from './../../assets/images/SimpleTags_icons_trailer.png';
import Truck from './../../assets/images/SimpleTags_icons_commercial truck.png';
import { useNavigate } from 'react-router-dom';
import { GET_SERVICE_TYPE } from '../../app/services/service-type.service';
import { CREATE_DMV_DETAILS } from '../../app/services/DMV.service';
import { Loader } from '../loader';
import { ServiceTypeData, ServiceTypeResponse } from '../../app/model/service-types';
import { DynamicObject, GetParamsData } from '../../app/model/common';
import { DVMDetailsRegistration, DVMDetailsResponse } from '../../app/model/dvm-details';
import { GET_SYSTEM_PARAMS } from '../../app/services/system-params.service';
import toast from 'react-hot-toast';

export const SelectService: React.FC = () => {
    const selectedServiceType = sessionStorage.getItem('selectedServiceType');
    const selectedUserEmail = sessionStorage.getItem('user_email');
    const [DMVDetails, setDMVDetails] = useState<any>();

    const [dmvDetails, setDmvDetails] = useState<DVMDetailsRegistration>({
        service_type_uuid: selectedServiceType || '',
        vehicle_license_plate: "",
        user_email: selectedUserEmail || ''
    })
    const vehicleImages = [
        {
            title: "Car",
            image: Car
        },
        {
            title: "Bike",
            image: Bike
        },
        {
            title: "Ship",
            image: Ship
        },
        {
            title: "Trolly",
            image: Trolly
        },
        {
            title: "Truck",
            image: Truck
        },
    ]
    const [formErrors, setFormErrors] = useState<DynamicObject>({});
    const navigation = useNavigate();
    const [serviceTypes, setServiceTypes] = useState<ServiceTypeData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{2,6}$/;
    const [pageCount, setPageCount] = useState<GetParamsData>({
        limit: 100,
        page: 0,
    });
    useEffect(() => {
        getServiceTypes();
        getSystemParams();
    }, [])

    const getSystemParams = () => {
        GET_SYSTEM_PARAMS({ code: 'DMV_LOGO' }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    if (res?.data?.data?.length > 0) {
                        setDMVDetails(res?.data?.data[0]);
                    }
                    setLoading(false);
                }
            }
        });
    }

    const getServiceTypes = () => {
        setLoading(true);
        GET_SERVICE_TYPE(pageCount).then((res: ServiceTypeResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    if (res?.data?.data?.length > 0) {
                        res?.data?.data?.forEach((element: ServiceTypeData) => {
                            element.label = element?.name;
                            element.value = element?.uuid;
                        });
                        setServiceTypes([...res?.data?.data]);
                    }
                    setLoading(false);
                }
            }
        });
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setDmvDetails((prevData: DVMDetailsRegistration) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, value),
        }));
    }

    const validateSpecificField = (name: string, value: string) => {
        if (name === "user_email") {
            if (!value) {
                return "Please enter email address";
            } else if (!emailRegEx.test(value)) {
                return "Please enter a valid email address";
            }
        }

        if (name === "service_type_uuid") {
            if (!value) {
                return "Please select the service type"
            }
        }
        return "";
    }

    const validateForm = () => {
        const errors: DynamicObject = {};
        if (!dmvDetails?.service_type_uuid) {
            errors.service_type_uuid = 'Please select service type'
        }

        if (!dmvDetails?.vehicle_license_plate) {
            errors.vehicle_license_plate = 'Please enter license plate'
        }

        if (!dmvDetails.user_email) {
            errors.user_email = 'Please enter email';
        } else if (!emailRegEx.test(dmvDetails.user_email)) {
            errors.user_email = 'Please enter a valid email address';
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onGetStart = () => {
        const isValid = validateForm();
        if (isValid) {
            setLoading(true);
            CREATE_DMV_DETAILS(dmvDetails).then((res: DVMDetailsResponse) => {
                if (res?.status === "fail") {
                    toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                    setLoading(false);
                } else {
                    if (res) {
                        navigation(`/checking-dmv-records/${res?.data?.user_uuid}/${res?.data?.user_vehicle_uuid}`);
                        setLoading(false);
                    }
                }
            });
        } else {
            console.log("please enter valid details:");
        }
    }

    return (
        <div className='col-md-12 primary-bg px-lg-4 px-lg-5 py-5'>
            <div className='container px-4'>
                <Loader LoadingReducer={loading} />
                <h4 className='text-center font-proxima-nova fs-28 fw-900 mb-0'>Select a Service for Vehicle Registration or Title</h4>
                <div className='row'>
                    <div className='col-12 col-lg-11 mx-lg-auto'>
                        <div className='d-flex justify-content-center pt-2 pt-md-0 pb-3 pb-md-0 py-md-0'>
                            {vehicleImages?.map((vehicle: DynamicObject, index: number) => (
                                <div className='mx-sm-4 px-sm-2 mx-xl-4 mx-xxl-4 px-xl-5' key={index}>
                                    <img src={vehicle?.image} className='vehicle-image' />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='row '>
                    <div className='col-12 col-lg-11 border-0 pt-4 px-lg-3 bg-white rounded-4 mx-auto'>
                        <div className='row mx-2 mx-md-5 ms-xl-5 ps-xl-5'>
                            <div className='col-md-10 col-xl-4 px-xl-0 my-3 my-xl-0 mx-md-4 mx-xl-0'>
                                <select className='form-select custom-select rounded-end-0' name="service_type_uuid" value={dmvDetails?.service_type_uuid} onChange={(e) => handleInput(e)} >
                                    <option value="">Select Service Type</option>
                                    {serviceTypes?.map((service: ServiceTypeData, i: number) => (
                                        <option value={service.value} key={i} >{service?.label}</option>
                                    ))}
                                </select>
                                {formErrors?.service_type_uuid && (
                                    <div className="text-danger text-start w-100">{formErrors?.service_type_uuid} </div>
                                )}
                            </div>
                            <div className='col-md-10 col-xl-3 px-xl-0 my-3 my-xl-0 mx-md-4 mx-xl-0'>
                                <input type="text" name="vehicle_license_plate" className='form-control rounded-0' placeholder='License Plate' defaultValue={dmvDetails?.vehicle_license_plate} onChange={(e) => handleInput(e)} />
                                {formErrors?.vehicle_license_plate && (
                                    <div className="text-danger text-start w-100">{formErrors?.vehicle_license_plate} </div>
                                )}
                            </div>
                            <div className='col-md-10 col-xl-3 px-xl-0 my-3 my-xl-0 mx-md-4 mx-xl-0'>
                                <input type="text" name="user_email" className='form-control rounded-start-0' placeholder='Email Address' defaultValue={dmvDetails?.user_email} onChange={(e) => handleInput(e)} />
                                {formErrors?.user_email && (
                                    <div className="text-danger text-start w-100">{formErrors?.user_email} </div>
                                )}
                            </div>
                            <div className='col-md-10 col-xl-2 px-xl-0 my-3 my-xl-0 text-end text-xl-start mx-md-4 mx-xl-0'>
                                <button className='p-1 px-3 rounded border-0 primary-bg primary-button font-Oswald' onClick={onGetStart}>Get Started</button>
                            </div>
                        </div>
                        <div className='row mx-md-5 ms-xl-5 ps-xl-5 my-2 mb-lg-0 mb-3'>
                            <div className='col-12 d-sm-flex justify-content-start'>
                                <div>
                                    <img src={DMVDetails?.value?.dmv_logo || DMV} alt="dmv" className='dmv-image' />
                                </div>
                                <div className='align-items-center mt-2'>
                                    <h4 className='mb-0 secondary-color text-center font-bebas-nue-pro-regular fw-500'>{DMVDetails?.value?.title ? DMVDetails?.value?.title : 'DMV Delegated Provider'}</h4>
                                    <div className='secondary-color font-Oswald text-lg-start text-center'>{DMVDetails?.value?.sub_title ? DMVDetails?.value?.sub_title : 'Officially Entrusted by the CA Department of Motor Vehicles'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

