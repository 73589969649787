import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { LOGIN_USER, SEND_OTP, USER_AUTHENTICATIONS } from '../../app/services/login.service';
import toast from "react-hot-toast";
import { Loader } from '../../components/loader';
import { LoginUserResponse, SendOTPPayload } from '../../app/model/login';
import { CommonResponse, DynamicObject } from '../../app/model/common';

interface Props {
    isLoginPage?: any;
}

export const LogIn: React.FC = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigation = useNavigate();
    const [emailData, setEmailData] = useState<SendOTPPayload>({
        email: ""
    })
    const [otpData, setOTPData] = useState<SendOTPPayload>({
        email: "",
        otp: ""
    })
    const [formErrors, setFormErrors] = useState<DynamicObject>({});
    const [showOtp, setShowOtp] = useState<boolean>(false);

    useEffect(() => {
        const data = sessionStorage?.getItem('login-status')//props?.isLoginPage
        if (data) {
            setShowOtp(false)
        }
    }, [])


    const onGetOTP = () => {
        const isValid = validateForm();
        setLoading(true);
        if (isValid) {
            const payload = {
                user_uuid: '',
                otp_type: "email",
                mobile_number: "",
                email: emailData?.email,
                param_code: "EMAIL_LOGIN_REGISTER"
            }
            USER_AUTHENTICATIONS(payload).then((res: any) => {
                if (res?.status === "fail") {
                    setLoading(false);
                    toast.error(res?.message, { position: "top-right" });
                } else {
                    if (res) {
                        const data = otpData;
                        data.otp = res?.data?.otp_value
                        setOTPData({ ...data })
                        setLoading(false);
                        setShowOtp(true);
                        toast.success(res?.message, { position: "top-right" });
                    }
                }
            });
        } else {
            setShowOtp(false)
        }
    }

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setEmailData((prevData: SendOTPPayload) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, value),
        }));
    }

    const handleChangeOtp = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setOTPData((prevData: SendOTPPayload) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, value),
        }));
    }

    const validateForm = () => {
        const errors: DynamicObject = {};
        if (!emailData?.email) {
            errors.email = "Please enter email address";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const validateSpecificField = (name: string, value: string) => {
        if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!value) {
                return "Please enter email address"
            } else if (!emailRegex.test(value)) {
                return "Please enter a valid email address";
            }
        }

        if (name === "otp") {
            const otpRegEX = /^\d{4}$/
            if (!value) {
                return "Please enter otp"
            } else if (!otpRegEX.test(value)) {
                return "Please enter a valid otp"
            }
        }
        return "";
    }

    const validateOTPForm = () => {
        const errors: DynamicObject = {};
        if (!otpData?.otp) {
            errors.otp = "Please enter otp"
        }
        setFormErrors(errors)
        return Object.keys(errors).length === 0;
    }

    const onSubmit = () => {
        setLoading(true);
        const isValid = validateOTPForm();
        if (isValid) {
            const payload = {
                "type": "email",
                "email": emailData?.email,
                "mobile_country_code": "",
                "mobile_number": "",
                "password": ""
            }
            LOGIN_USER(payload).then((res: LoginUserResponse) => {
                if (res?.status === "fail") {
                    setLoading(false);
                    // toast.error("Invalid email!", { position: "top-right" });
                    toast.error(res?.error?.data, { position: "top-right" });
                    const errors: DynamicObject = {};
                    errors.otp = res?.error?.data;
                    setShowOtp(false);
                    setFormErrors({ ...errors });
                } else {
                    if (res) {
                        setLoading(false);
                        navigation(`/dashboard/my-orders/${res?.data?.user_uuid}`);
                        sessionStorage.setItem('user_id', res?.data?.user_uuid);
                        sessionStorage.setItem('user_name', res?.data?.user_name ? res?.data?.user_name : "");
                        sessionStorage.setItem('user_email', res?.data?.user_email ? res?.data?.user_email : "");
                        toast.success(res?.message, { position: "top-right" });
                    }
                }
            });
        } else {
            setLoading(false);
        }
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (event.key === 'Enter') {
            onGetOTP();
        }
    };

    const handleKeyDownOTP = (event: React.KeyboardEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <div className='container-fluid login-container '>
            <div className="row d-flex flex-column justify-content-center ">
                <Loader LoadingReducer={loading} />
                <div className="col-md-12 mt-5">
                    <div className='text-center pt-3'>
                        <h4 className='primary-color font-Oswald fs-40 fw-400 ls-2'>LOG INTO YOUR SIMPLE TAGS ACCOUNT</h4>
                        {!showOtp && (<>
                            <div className='font-proxima-nova my-3 fw-bold fs-20'>Log in using your email address to view your orders.</div>
                            <div className='col-10 col-lg-5 col-xl-4 px-md-5 px-lg-4 mx-auto my-3'>
                                <input type="text" className="form-control border-dark" name="email" placeholder='' defaultValue={""} onChange={handleChangeEmail} onKeyDown={handleKeyDown} />
                                {formErrors.email && (<div className="text-danger text-start w-100"> {formErrors.email} </div>)}
                            </div>
                            <div className='my-1'>
                                <button className='secondary-button border-0 rounded px-3' onClick={onGetOTP}>GET OTP</button>
                            </div>
                        </>)}
                        {showOtp && (
                            <div className='col-8 col-lg-4 col-xl-3 px-md-5 px-lg-4 mx-auto my-3'>
                                <label className='font-proxima-nova my-3 fw-normal d-flex justify-content-start'>Please enter OTP</label>
                                <div className=''>
                                    <input type="text" maxLength={6} className="form-control border-dark" name="otp" placeholder='' value={otpData?.otp} onChange={handleChangeOtp} onKeyDown={handleKeyDownOTP} />
                                    {formErrors.otp && (<div className="text-danger text-start w-100"> {formErrors.otp} </div>)}
                                </div>
                                <div className='my-2'>
                                    <button className='secondary-button border-0 rounded px-3' onClick={onSubmit}>SUBMIT</button>
                                </div>
                            </div>
                        )}
                        {/* <div className='d-block d-md-none'>Have a business account? <a className='text-decoration-none fw-bold'>Click here</a> to log in.</div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
