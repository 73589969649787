import React, { useEffect } from 'react'

export const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className='primary-bg'>
            <div className='container'>
                <div className='row mx-0 '>
                    <div className='col-md-12 py-4 primary-bg mx-md-auto'>
                        <div className='row py-4 p-md-3 mx-3 mt-xl-5 pt-xl-4'>
                            <div className='col-11 col-md-12 bg-white py-3 p-md-3 p-md-5 mx-auto rounded-4 border-0 text-center'>
                                <div className='px-md-4 py-5'>
                                    <h2 className='primary-color'>ALL THINGS DMV HASSLE-FREE</h2>
                                    <h4 className='mb-5 fw-bold'>Updated: January 1, 2024</h4>
                                    <div className='text-start'>
                                        <h6 className='fw-bold fs-22 py-xl-4'>Introduction</h6>
                                        <p className='fw-500'>Welcome to <a className="fw-bold text-decoration-none" >www.ein-federal-tax-id.com</a>! We value your privacy. This Privacy Policy applies to this website,<a className="fw-bold text-decoration-none" href='https://www.ein-federal-tax-id.com/' target='_blank'>www.ein-federal-tax-id.com</a>, and its subdomains (collectively, the "Website" or "Site"), and all portals, products, goods, services, events, and interactive features, or other services controlled by EIN Federal Tax ID (a wholly owned subsidiary of Simple Biz Forms, LLC) (referred to herein as "EINFTID", "we", "us", or "our") that post a link to this Privacy Policy. This Privacy Policy is expressly incorporated into our <a className='text-decoration-none fw-bold'>Terms & Conditions</a> ("Terms") and governs information that we collect from you on the Site, as well as through other online and offline communications with our personnel. We refer to all of the above as our "Services." If you have questions about this Privacy Policy, contact us at <a className='text-decoration-none fw-bold'>privacy@ein-federal-tax-id.com</a>.</p>
                                        <p className='fw-500'>As a preliminary matter, we handle all information you provide us with the utmost care. At times, we may need to disclose your personal information to third parties outside the company who may be assisting us with providing Services to you, as set forth in this Policy.</p>
                                        <p className='fw-500'>Please note that if you voluntarily provide your information in the course of interfacing with the Site or using our Services, we will take that as your agreement to our collection, use, and disclosure of your information as set forth in this Privacy Policy.</p>
                                        <p className='fw-500'> This Privacy Policy does not apply to any products, goods, services, websites, or content that are offered by third parties ("Third Party Services"). which are governed by their respective privacy policies.</p>
                                        <hr />
                                        <ol className='fw-bold fs-5 my-4'>
                                            <li>What Information We Collect</li>
                                            <li>How We Use Your Information</li>
                                            <li>Disclosure of Your Information</li>
                                            <li>Cookies and other Tracking Technologies</li>
                                            <li>Do Not Track Signals</li>
                                            <li>Your Choices About the Information We Collect</li>
                                            <li>Children's Privacy</li>
                                            <li>Security</li>
                                            <li>Links to Other Web Sites</li>
                                            <li>Updating Personal Information</li>
                                            <li>International Data Transfers</li>
                                            <li>Your California Privacy Rights</li>
                                            <li>Changes in Privacy Policy</li>
                                            <li>Contact Us</li>
                                        </ol>
                                        <hr />
                                        <ul>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>1. What Information We Collect</h5>
                                                <p className='fw-500'>We may collect information about you when you visit our Site or otherwise interact with our Site or Services.</p>
                                                <h5 className='fw-bold'>a. Information You Provide to Us</h5>
                                                <p className='fw-500'>We collect information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or household ("Personal Information"). During your use of the Services, we collect Personal Information such as:</p>
                                                <ul className='mb-4 what-information'>
                                                    <li className='list-style-dot  mb-3 fw-500'>Your first and last name, email address, home or business address, shipping address, telephone numbers, mobile numbers, credit card or debit card number, your payment and/or service history,</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Your social security number or other government-issued identification, if needed to establish a Federal Tax ID number,</li>
                                                    <li className='list-style-dot  mb-3 fw-500'>Your employer and/or job title or corporate information that you provide to us;</li>
                                                    <li className='list-style-dot  mb-3 fw-500'>Information that you share via the chat function on the Site (please note that we do not collect consumer information via the Site's online chat or Contact Us function unless you provide it):</li>
                                                    <li className='list-style-dot  mb-3 fw-500'>If you apply for employment with us, your current or past employment or educational information; and/o</li>
                                                    <li className='list-style-dot  mb-3 fw-500'>Other information that could reasonably be used to identify you personally or identify your household or device.</li>
                                                </ul>
                                                <p className='fw-500' >We may also obtain Personal Information from you where you expressly provide us with the information. Examples of sources from which we collect information include telephone calls with you, letters, e-mails or other communications from you, information provided via online chat or support services, web forms or inputs/uploads into our Site, documents you have provided to us, or references and referrals provided to us in connection with your employment application.</p>
                                                <p className='fw-500'>Your decision to provide us with Personal Information is voluntary, but if you choose not to provide any requested information, you may not be able to take advantage of all the features of the Site or our Services.</p>
                                                <h5 className='fw-bold'>b. Information That Is Automatically Collected</h5>
                                                <p className='fw-500'>Like many businesses, we and/or our service providers may automatically collect and/or store certain information when you visit or interact with the Site ("Usage Information"). This Usage Information may be stored and/or accessed from your personal computer, laptop, tablet, mobile phone or other device (a "Device") whenever you visit or interact with our Site. Usage Information may include:</p>
                                                <ul>
                                                    <li className='list-style-dot mb-3 fw-500'>Your IP address, IDFA, Android/Google Advertising ID, IMEI, or another unique identifier.</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Your Device functionality (including browser, browser language, operating system, hardware, mobile network information);</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Referring and exit web pages and URLs;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The areas within the Site that you visit and your activities there, including remembering you and your preferences;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Your Device location or other geolocation information, including the zip code, state or country from which you accessed the Services;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Your Device characteristics; and</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Certain other Device data, including the time of day you visit our Site or other information used to provide analytics or other usage information.</li>
                                                </ul>
                                                <p className='fw-500'>For location information, we may use this information to provide customized Services, content, promotional offers, and other information that may be of interest to you.</p>
                                                <p className='fw-500'>If you no longer wish for us or our service providers to collect and use location information, you may disable the location features on your device. Consult your device manufacturer settings for instructions. Please note that if you disable such features, your ability to access certain features, Services, content, promotions, or products may be limited or disabled.</p>
                                                <h5 className='fw-bold'>c. Information from Third Parties</h5>
                                                <p className='fw-500'>The Site may include functionality that allows certain kinds of interactions between the Site and your account on a third-party website or application. The use of this functionality may involve the third-party site providing information to us or us providing information to the third party. For example, we may provide links on the Site to facilitate sending a communication from the Site, or we may use third parties to facilitate emails or postings to social media (like a "Share" or "Forward" button). These third parties may retain any information used or provided in any such communications or activities and these third parties' practices are not subject to our Privacy Policy. We may not control or have access to your communications through these third parties. Further, when you use third-party sites or services, you are using their services and not our services and they, not we, are responsible for their practices. You should read the applicable third-party privacy policies before using such third-party tools on our Site. Please also see our Terms & Conditions for further information.</p>
                                            </li>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>2. How We Use Your Information</h5>
                                                <p className='fw-500'>We may use your information for various purposes, including the following:</p>
                                                <ul>
                                                    <li className='list-style-dot mb-3 fw-500'>Responding to your requests for information;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Providing products or Services to you, or updating you on the status of an order,</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Verifying your identity and for fraud prevention;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Processing your payments (we use a third-party processor for payments and never retain your payment card information):</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Providing you with updates and information about products and Services we provide:</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Sending you marketing information about EINFTID;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Sending you email communications such as electronic newsletters about our Services, events and promotions, as well as periodic customer satisfaction, market research or quality assurance surveys that may be of interest to you;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Improving the effectiveness of our Site, our marketing endeavors, and our product and service offerings;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Customizing your experience on the Site;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Identifying your product and service preferences, providing personalized content and ads and informing you of new or additional information, products and services that may be of interest to you;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Helping us address problems with and improve our Site and our products and services, including testing and creating new products, features, and services;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Providing order-related messages (including reminders and updates on your orders) and other communications and messages;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Protecting the security and integrity of the Site, including understanding and resolving any technical and security issues reported on our Site:</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Engaging in analysis, research, and reports regarding the use of our Site and Services;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>For internal business purposes, including but not limited to evaluating your employment application or administering employee benefits, such as medical, dental, commuter and retirement benefits, including recording and processing eligibility of dependents, absence and leave monitoring, insurance and accident management, rewards or discount programs offered to employees;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Complying with the law and protecting the safety, rights, property or security of EINFTID, the Services, and the general public; and</li>
                                                    <li className='list-style-dot mb-3 fw-500'>For other purposes disclosed at the time you provide your information or as otherwise set forth in this Privacy Policy.</li>
                                                </ul>
                                            </li>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>3. Disclosure of Your Information</h5>
                                                <p className='fw-500'>
                                                    We may share your information, including your Personal Information, with government agencies issuing the tax ID number, as well as with our
                                                    affiliates, service providers, business associates, and other third parties that perform essential services for us; for example, a payment processor, a cloud-service or internet service provider, an email marketing vendor, a customer service provider, or entities that handle other business functions for
                                                    us.
                                                </p>
                                                <p className='fw-500'>
                                                    As permitted by law, we insist that these partners follow the same rules and standards with respect to your personal information as we do, and we typically have a contractual agreement in place with the other party which prohibits them from disclosing or using the information other than for the purposes for which it was disclosed.
                                                </p>
                                                <p className='fw-500'>
                                                    We do not sell, rent or lease our customer lists to third parties. If you are a California resident, you may have additional rights with respect to the transmission of your information for this purpose. Please see the section entitled "Your California Privacy Rights" below for more information. We may also share the information we have collected about you, including Personal Information, as may be disclosed at the time you provide your information in addition to as described in this Privacy Policy.
                                                </p>
                                                <p className='fw-500'>
                                                    We may aggregate, de-identify, and/or anonymize any information collected through the Site or Services such that such information is no longer linked to your Personal Information. We may use and share this aggregated and anonymized information (which is no longer Personal Information) for any purpose, including without limitation, for research and marketing purposes, analytics, and may also share such data with our affiliates and third parties, including advertisers, promotional partners and others.
                                                </p>
                                                <p className='fw-500'>
                                                    Certain Personal Information about you may also be disclosed in the following situations:
                                                </p>
                                                <ul className=''>
                                                    <li className='list-style-dot mb-3 fw-500'>To comply with a validly issued and enforceable subpoena or summons;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>As necessary to properly comply with or respond to an inquiry or complaint from a federal or state agency.</li>
                                                    <li className='list-style-dot mb-3 fw-500'>In conjunction with a prospective purchase, sale, or merger of all or part of our practice, provided that we take appropriate precautions (for example, through a written confidentiality agreement) so the prospective purchaser or merger partner does not disclose information obtained in the course of the review, and</li>
                                                    <li className='list-style-dot mb-3 fw-500'>As a part of any actual or threatened legal proceedings or alternative dispute resolution proceedings either initiated by or against us, provided we disclose only the information necessary to fille, pursue, or defend against the lawsuit and take reasonable precautions to ensure that the information disclosed does not become a matter of public record.</li>
                                                </ul>
                                            </li>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>4. Cookies and Other Tracking Technologies</h5>
                                                <p className='fw-500'>
                                                    We may use various methods and technologies to store or collect information about you ("Tracking Technologies"). Tracking Technologies may set, change, alter or modify settings or configurations on your Device. A few of the Tracking Technologies used on the Site, include, but are not limited to, the following (as well as future-developed tracking technology or methods that are not listed here):
                                                </p>
                                                <ol>
                                                    <li className='list-style-dot mb-3 fw-500'>
                                                        Cookies. Cookies are pieces of data stored directly on the computer or mobile device that you are using. Cookies allow us to collect data such as browser type, time spent on the Online Services, pages visited, referring URL, language preferences, and other aggregated traffic data.</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Web Beacons. A Web Beacon is a small tag (which may be invisible to you) that may be placed on our Site's pages and messages.</li>
                                                    <li>Tracking Pixels. Tracking pixels (also called pixel tags, or clear gifs), are small graphics that are loaded when a user visits a webpage or opens an email. Pixels may be used to identify the operating system used, the type of website or email used, or the time the email was read or the website was visited. They may be shared with third parties that directly support our promotional activities and website development. Information collected through pixel tags is not necessarily personally identifiable, but it could be linked to your Personal Information.</li>

                                                    <li className='list-style-dot mb-3 fw-500'>Embedded Scripts. An embedded script is programming code that is designed to collect information about your interactions with the Site, such as the links you click on.</li>
                                                    <li className='list-style-dot mb-3 fw-500'>ETag, or entity tag. An Etag or entity tag is a feature of the cache in browsers. It is an opaque identifier assigned by a web server to a specific version of a resource found at a URL.</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Browser Fingerprinting. Collection and analysis of information from your Device, such as, without limitation, your operating system, plug- ins, system fonts and other data, for purposes of identification.</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Recognition Technologies. Technologies, including application of statistical probability to data sets, which attempt to recognize or make assumptions about users and devices (e.g., that a user of multiple devices is the same user).</li>
                                                </ol>

                                                <p className='fw-500'>We may use Tracking Technologies for a variety of purposes, including:</p>
                                                <ul>
                                                    <li className='list-style-dot mb-3 fw-500'>To allow you to use and access the Site, including for the prevention of fraudulent activity and improved security functionality,</li>
                                                    <li className='list-style-dot mb-3 fw-500'>To facilitate navigation, display data more effectively, collect statistical data, personalize your experience while using the Online Services and recognize your computer to assist your use of the Online Services;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>To assess the performance of the Site, including as part of our analytics practices or otherwise to improve the design and functionality. content, products or services offered through the Site;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>To offer you enhanced functionality when accessing the Site, including identifying you when you sign into our Site or keeping track of your specified preferences or to track your online activities over time and across third-party sites; and</li>
                                                    <li className='list-style-dot mb-3 fw-500'>To deliver content relevant to your interests on our Site and third-party sites based on how you interact with our content.</li>
                                                </ul>
                                                <p className='fw-500'>
                                                    You can choose whether to accept cookies and other Tracking Technologies by changing the settings on your browser. If you choose not to enable cookies, you will still be able to browse our Site, but doing so will restrict some of the functionality of our Site and what you can do.
                                                </p>
                                                <p className='fw-500'>
                                                    You can set your web browser to alert you when a cookie is being used. You can also get information on the duration of the cookie and what server your data is being returned to. You then can accept or reject the cookie. Additionally, you can set your browser to refuse all cookies or accept only cookies returned to the originating servers.
                                                </p>
                                                <p className='fw-500'>
                                                    You can opt in or out of cookies at any time - except strictly necessary cookies (these are used to help make our website work efficiently).
                                                </p>
                                                <p className='fw-500'>
                                                    You can enable or disable cookies in your browser. If you wish to restrict or block the cookies set by any website - you can do this through the web browser settings for each web browser you use, on each device you use to access the internet. Some of the more popular browsers (and links to manage your cookies on each) are:
                                                </p>
                                                <h6 className='fw-bold '>1. Internet Explorer</h6>
                                                <h6 className='fw-bold'>2. Firefox</h6>
                                                <h6 className='fw-bold'>3. Safari</h6>
                                                <h6 className='fw-bold mb-3'>4. Google Chrome</h6>
                                                <p className='fw-500'>Information on controlling and deleting cookies, including on a wide variety of browsers, is also available at <a className='text-decoration-none fw-bold'>allaboutcookies.org</a>.</p>
                                                <p className='fw-500'>If you choose not to enable cookies, you will still be able to browse our Site, but it will restrict some of the functionality of our Site and what you can do.</p>
                                                <p className='fw-500'>Some services may not function or may have more limited functionality if your web browser does not accept cookies. However, you can allow cookies from specific websites by making them "trusted websites' in your web browser.</p>
                                                <p className='fw-500'>If you want to opt-out of cookies across different advertising networks, the Network Advertising Initiative website -<a className='text-decoration-none fw-bold'>www.networkadvertising.org</a>- has more information and guidance.</p>
                                                <p className='fw-500'>If you don't wish to accept cookies from one of our emails, you can choose not to download any images or click on any links. You can also set your browser to restrict cookies or to reject them entirely. These settings will apply to all cookies, whether included on websites or in emails. Depending on your email or browser settings, cookies in an email may sometimes be automatically accepted (for example, when you've added an email address to your address book or safe senders list). For more information, refer to your email browser or device instructions. We may also collect data through Google Analytics, or similar applications, which use cookies and technologies to collect and analyze data about use of the Services. These services collect data regarding the use of other websites, apps and online resources. You can learn about Google's practices by going to <a className='text-decoration-none fw-bold'>www.google.com/policies/privacy/partners/</a> and opt out by downloading the Google Analytics opt out browser add-on, available at <a className='text-decoration-none fw-bold'>https://tools.google.com/dlpage/gaoptout</a></p>
                                            </li>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>5. Do Not Track Signals</h5>
                                                <p className='fw-500'>Do Not Track ("DNT") is a web browser setting that requests that a web application disable its tracking of an individual user. When you choose to turn on the DNT setting in your browser, your browser sends a special signal to websites, analytics companies, ad networks, plug in providers, and other web services you encounter while browsing to stop tracking your activity. Various third parties are developing or have developed signals or other mechanisms for the expression of consumer choice regarding the collection of information about an individual consumer's online activities over time and across third-party websites or online services (e.g., browser do not track signals), but there is no universally agreed-upon standard for what a company should do when it detects a DNT signal. Currently, we do not monitor or take any action with respect to these signals or other mechanisms. You can learn more about Do Not Track <a className='text-decoration-none fw-bold'>here</a>.
                                                </p>
                                            </li>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>6. Your Choices About the Information We Collect</h5>
                                                <p className='fw-500'>
                                                    You have choices when it comes to how we use your information, and we want to ensure you have the information to make the choices that are right for you.
                                                </p>
                                                <p className='fw-500'>
                                                    If you no longer want to receive marketing-related emails, you may opt out by following the instructions in any such email you receive from us or contacting us at <a className='text-decoration-none fw-bold'>privacy@ein-federal-tax-id.com</a>. If you would prefer that we not share your name and mailing address with third parties (other than with our affiliates) to receive promotional offers, you have the option to opt out of such sharing. To do so, please email us at <a className='text-decoration-none fw-bold'>privacy@ein-federal-tax- id.com</a> . Your choice will not affect our ability to share information in the other ways described in this Privacy Policy.
                                                </p>
                                            </li>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>7. Children's Privacy</h5>
                                                <p className='fw-500'>
                                                    The Site is not intended for use by children under the age of 18. We do not request, or knowingly collect, any personally identifiable information from children under the age of 18. If you are the parent or guardian of a child under 18 who you believe has provided her/his/its/their information to us, please contact us at <a className='fw-bold'>privacy@ein-federal-tax-id.com</a> to request the deletion of that information.
                                                </p>
                                            </li>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>8. Security</h5>
                                                <p className='fw-500'>
                                                    We use reasonable organizational, technical and administrative measures to protect Personal Information. Unfortunately, no data transmission or storage system can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no longer secure (for example, if you feel that the security of your account has been compromised), please immediately notify us in accordance with the "Contact Us" section, below. 9. Links to Other Web Sites
                                                </p>
                                            </li>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>9. Links to Other Web Sites</h5>
                                                <p className='fw-500'>
                                                    For your convenience, the Site and this Privacy Policy may contain links to other websites. EINFTID is not responsible for the privacy practices, advertising, products, services, or the content of such other websites. None of the links on the Site should be deemed to imply that EINFTID endorses or has any affiliation with the links. Please see our Terms & Conditions for more information.
                                                </p>
                                            </li>

                                            <li className='list-style-type-none'><h5 className='fw-bold'>
                                                10. Updating Personal Information
                                            </h5>
                                                <p className='fw-500'> 
                                                    We prefer to keep your Personal Information accurate and up to date. If you would like to change your contact information, please contact us at support@ein-federal-tax-id.com. We will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable (but we may retain prior information as business records).
                                                </p>
                                            </li>
                                            <li className=''>
                                                <h5 className='fw-bold'> 11. International Data Transfers</h5>
                                                <p className='fw-500'>
                                                    We operate in and use service providers located in the United States, however, because EINFTID works with global companies and technologies, your Personal Information may be transferred outside of the country in which it was originally provided. This may include transfers to third parties. such as service providers or affiliated entities who are located outside the United States or the European Union, where data protection laws may not offer the same level of protection as those in the United States. When we transfer Personal Information outside of these areas, we take steps to make sure that appropriate safeguards are in place to protect your personal information.
                                                </p>
                                                <p className='fw-500'>
                                                    This section of the Privacy Policy applies solely to California residents. We adopt this Policy to comply with the California Consumer Privacy Act of 2018 ("CCPA") and the California Privacy Reform Act ("CPRA"). Any terms defined in the CCPA or CPRA have the same meaning when used in this Section.
                                                </p>
                                                <p className='fw-500'> 
                                                    California residents have the following rights:
                                                </p>
                                                <ul className=''>
                                                    <li className='list-style-dot mb-3 fw-500'>The right to know what personal information is being collected about you;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The right to know whether your personal information is sold or disclosed and to whom;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The right to access your personal information:</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The right, in certain circumstances, to delete the information you have provided to us;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The right to correct your personal information;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The right to limit use of sensitive personal information;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Right to reject automated decision making and profiling:</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Right to access information about automated decision making:</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The right to opt out of the sale of personal information; and</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The right not to be discriminated against, even if you exercise your privacy rights.</li>
                                                </ul>
                                                <div className='py-3 table-responsive'>
                                                    <table className='privacy-policy table table-bordered border-dark border-2'>
                                                        <thead className='fw-bold fs-4'>
                                                            <tr>
                                                                <th>Category of Personal Information Collected</th>
                                                                <th>Collected
                                                                    (Yes/No)
                                                                </th>
                                                                <th>Sources of Collected
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr className='fw-500'>
                                                                <td className='fw-500'>
                                                                    EINFTID collects certain types of personal information about you during your relationship with us, as stated above. In particular, our Site has collected the following categories of Personal Information from consumers within the last twelve (12) months:
                                                                </td>
                                                                <td>
                                                                    Yes
                                                                </td>
                                                                <td>
                                                                    You Automatically
                                                                    Third Parties
                                                                </td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>
                                                                    Sensitive Personal Information such as social security
                                                                    number, driver's license number, Account log-in, debit, or credit card number in combination with password or PIN, precise geolocation, racial/ethnic origins, religious or philosophical beliefs, union membership, contents of e-mails or texts to others, genetic/biometric data, health information, sex life/sexual orientation data
                                                                </td>
                                                                <td>Yes</td>
                                                                <td>You</td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code 1798.80(e))
                                                                </td>
                                                                <td>Yes</td>
                                                                <td>You Automatically Third Parties
                                                                </td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>Characteristics of protected classifications under California or federal law
                                                                </td>
                                                                <td>No</td>
                                                                <td>N/A</td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>Commercial information including records of personal property, products or services purchased, obtained, or
                                                                </td>
                                                                <td>Yes</td>
                                                                <td>You Automatically
                                                                </td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>Biometric information
                                                                </td>
                                                                <td>No</td>
                                                                <td>N/A</td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>Internet or other electronic network activity information including, but not limited to, browsing history, search history. and information regarding a consumer's interaction with an internet website, application, or advertisement
                                                                </td>
                                                                <td>Yes</td>
                                                                <td>Automatically
                                                                </td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>Geolocation data
                                                                </td>
                                                                <td>Yes</td>
                                                                <td>You Automatically Third Parties
                                                                </td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>Audio, electronic, visual, thermal, olfactory, or similar information
                                                                </td>
                                                                <td>No</td>
                                                                <td>N/A</td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>Professional or employment-related information
                                                                </td>
                                                                <td>Yes</td>
                                                                <td>You</td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>Education information (as defined in 20 U.S.C. section 1232g. 43 C.F.R. Part 99)
                                                                </td>
                                                                <td>Yes
                                                                    (for job applicants)
                                                                </td>
                                                                <td>You Third Parties
                                                                </td>
                                                            </tr>
                                                            <tr className='fw-500'>
                                                                <td>Inferences drawn from any of the of the information above to create a profile about a consumer reflecting the consumer's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.
                                                                </td>
                                                                <td>Yes</td>
                                                                <td>You Automatically Third Parties
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <h6 className='fw-bold'>a. Request for Information or Deletion
                                                </h6>
                                                <p className='fw-500'>
                                                    California consumers have the right to request, under certain circumstances, that a business that collects personal information about the consumer disclose to the consumer the information listed below for the preceding 12 months:
                                                </p>
                                                <ul>
                                                    <li className='list-style-dot mb-3 fw-500'>The categories of personal information collected about you;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The categories of sources from which we collected your personal information;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The categories of personal information that we have sold or disclosed about you for a business purpose;</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The categories of third parties to whom your personal information was disclosed for a business purpose:</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Our business or commercial purpose for collecting or selling your personal information; and</li>
                                                    <li className='list-style-dot mb-3 fw-500'>The specific pieces of personal information we have collected about you.</li>
                                                </ul>
                                                <p className='fw-500'>
                                                    Please note that if we collected information about you for a single one-time transaction and do not keep that information in the ordinary course of business, that information will not be retained for purposes of a request under this section. In addition, if we have de-identified or anonymized data about you, we are not required to re-identify or otherwise link your identity to that data if it is not otherwise maintained that way in our records. You can also request that we delete your personal information. There may be certain exceptions to our obligation to delete your information such as if you have an existing account or transaction with us or if we have a legitimate business reason to keep your information.
                                                </p>
                                                <h6 className='fw-bold'>b. Do Not Sell My Personal Information</h6>
                                                <p className='fw-500'>As a California resident, you also have the right, at any time, to tell us not to sell personal information - this is called the "right to opt-out" of the sale of personal information. At this time, we do not sell our consumers' personal information to third parties. We will, however, honor your request to opt out of any marketing emails or correspondence.
                                                </p>
                                                <h6 className='fw-bold'>c. Right to Limit Use of Sensitive Personal Information</h6>
                                                <p className='fw-500'>California consumers have the right to limit the use of each type of sensitive personal information for each purpose with each type of third-party partner. Consumers can revoke this permission at any time. Please note that EINFTID only keeps your sensitive personal information for a limited time, and only for the transaction for which it is required. We do not provide your sensitive personal information to any third parties.
                                                </p>
                                                <h6 className='fw-bold'>d. Right Not to Be Discriminated Against</h6>
                                                <p className='fw-500'>We will not discriminate against you for exercising any of your rights under the CCPA or CPRA. Unless permitted by California law, we will not:</p>
                                                <ul>
                                                    <li className='list-style-dot mb-3 fw-500' >Deny you goods or services.</li>
                                                    <li className='list-style-dot mb-3 fw-500'>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.
                                                    </li>
                                                    <li className='list-style-dot mb-3 fw-500'>Provide you a different level or quality of goods or services.
                                                    </li>
                                                    <li className='list-style-dot mb-3 fw-500'>
                                                        Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.
                                                    </li>
                                                </ul>
                                                <p className='fw-500'>
                                                    However, as permitted by California law, we may offer you certain financial incentives that can result in different prices, rates, or quality levels. Any permitted financial incentive we offer will reasonably relate to the value of your Personal Information. Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time.
                                                </p>
                                                <h6 className='fw-bold'>
                                                    e. Third Party Marketing
                                                </h6>
                                                <p className='fw-500'>
                                                    California Civil Code Section 1798.83 permits our users who are California residents to request and obtain from us a list of what personal information (if any) we disclosed to third parties for their own direct marketing purposes in the preceding calendar year and the names and addresses of those third parties. We do not currently disclose personal information protected under this section to third parties for their own direct marketing purposes.</p>
                                                <h6 className='fw-bold'>f. Exercising Your California Privacy Rights</h6>
                                                <p className='fw-500'>
                                                    You or your authorized agent may make a request to access, correct, delete or opt-out of the sale of your information by contacting us as follows:
                                                </p>
                                                <div className='my-3 ms-3 ms-md-5'>
                                                    <div><strong> Email Address: privacy@ein-federal-tax-id.com</strong></div>
                                                    <div><strong> Telephone Number: 424-340-0677</strong></div>
                                                </div>
                                                <p className='fw-500'>If you use an authorized agent to submit your request, we may require proof of the written authorization you have given. We also may require you to confirm your identity and your residency in order to obtain the information, and you are only entitled to make this request twice in a 12-month period. For emails, please include "California Privacy Rights" as the subject line. You must include your full name, email address, and attest to the fact that you are a California resident. We will acknowledge your request within 10 days and respond to your request within 45 days or let you know if we need additional time. If you make this request by telephone, we may also ask you to provide the request in writing so that we may verify your identity. If we are unable to honor your request for any reason, we will notify you of the reason within the request time period.
                                                </p>
                                            </li>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>12. Changes in Privacy Policy</h5>
                                                <p className='fw-500'>We may elect to change or amend our Privacy Policy, in such event, we will post the policy changes in our Privacy Policy on the Site, and they will become effective on the date posted. If you are concerned about how your personal information is used, please visit our Site often for this and other important announcements and updates.
                                                </p>
                                            </li>
                                            <li className='list-style-type-none'>
                                                <h5 className='fw-bold'>13. Contact Us</h5>
                                                <p className='fw-500'>
                                                    If you have any questions about this Privacy Policy, please contact us as follows:
                                                </p>
                                                <div className='my-3 ms-3 ms-md-5'>
                                                    <div><strong> Email Address: privacy@ein-federal-tax-id.com</strong></div>
                                                    <div><strong> Telephone Number: 424-340-0677</strong></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}
