import React from 'react'
import onlineRegistrationIcon from "../../assets/images/SimpleTags_icons_replaceImage.png"
import replaceRegistrationIcon from "../../assets/images/SimpleTags_icons_replace.png";
import replaceLostTitleIcon from "../../assets/images/SimpleTags_icons_transfer title.png";
import transferTitleIcon from "../../assets/images/online-registration.png";
import { CommonStaticData } from '../../app/model/common';

export const VehicleServices: React.FC = () => {
    const vehicleServicesData: CommonStaticData[] = [
        {
            image: transferTitleIcon,
            title: "Online Registration Renewal",
            description: "No need to go to the DMW to renew your registration. Save time  and renew online with our hassle-free service for your car, motorcycle, EV, RV or boat"
        },
        {
            image: onlineRegistrationIcon,
            title: "Replace Registration Sticker/Card",
            description: "Renewed you registration but lost or damaged your license plate sticker?  Easily order a replacement with our online service for a new DMV registration sticker and card"
        },
        {
            image: replaceRegistrationIcon,
            title: "Replace Lost Title",
            description: "Lost or stolen car title? Order a duplicate title with our online service and skip the DMV lines. We make the process simple and easy to understand."
        },
        {
            image: replaceLostTitleIcon,
            title: "Transfer Title",
            description: "Did you buy, inherit, or receive a vehicle as a gift? Complete your title transfer online and avoid the DMV. No appointment necessary with our service, just provide the required information and receive your new title and registration sticker/card in the mail."
        },
    ]
    return (
        <div className="secondary-bg">
            <div className="container px-lg-5 px-4">
                <div className="row py-4">
                    {vehicleServicesData?.map((service: CommonStaticData, i: number) => (
                        <div className="col-xl-3 col-sm-6 col-12" key={i}>
                            <div className="text-center">
                                <img src={service?.image} className="vehicle-service-image" />
                            </div>
                            <div className="primary-color fw-500 text-center mt-4 mb-4 lh-1 fs-18">
                                {service?.title}
                            </div>
                            <div className="custom-gray px-3 mb-3 fs-14 fw-600">
                                {service?.description}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}