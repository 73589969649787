import React, { useEffect, useRef, useState } from 'react'
import ProfileImage from './../../../assets/images/profile.avif';
import { useParams } from 'react-router-dom';
import { GET_USER, UPDATE_USER } from '../../../app/services/user.service';
import toast from "react-hot-toast";
import { Loader } from '../../../components/loader';
import { DynamicObject } from '../../../app/model/common';
import { UserInfo, UserResponse } from '../../../app/model/user-info';
import { S3Helper } from '../../../app/utils/s3-helper';
import { GET_PRESIGNED_URL } from '../../../app/services/fileupload.service';
import { ShippingAddressForm, ShippingAddressResponse } from '../../../app/model/shipping-address';
import Sates from '../../../app/utils/state.json'
import { GET_USER_SHIPPING_ADDRESS, UPDATE_USER_SHIPPING_ADDRESS } from '../../../app/services/user-address-shipping.service';

export const MyProfile: React.FC = () => {
    const [image, setImage] = useState<any>(ProfileImage);
    const [imageUrl, setIamgeUrl] = useState<any>();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [formErrors, setFormErrors] = useState<DynamicObject>({});
    const params = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [userDetails, setUserDetails] = useState<UserInfo>({});
    const [isOwner, setIsOwner] = useState(true);

    const [shippingAddressData, setShippingAddressData] = useState<ShippingAddressForm>({
        first_name: "",
        last_name: "",
        city: "",
        state: "CA",
        zip_code: "",
        email: "",
        phone_no: "",
        is_owner: isOwner,
        company_name: "",
        street_address: "",
        unit: "",
        is_overnight_shipping: true,
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        getUserInfo();
    }, [])

    const getUserInfo = () => {
        setLoading(true);
        GET_USER({ uuid: params?.user_uuid }).then((res: UserResponse) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    res?.data?.data?.forEach((el: any) => {
                        el.address = el?.metadata?.address
                    })
                    setImage(res?.data?.data[0]?.profile_image ? res?.data?.data[0]?.profile_image : ProfileImage)
                    sessionStorage?.setItem('profile-image', res?.data?.data[0]?.profile_image ? res?.data?.data[0]?.profile_image : "")
                    sessionStorage.setItem('user_name', res?.data?.data[0]?.name ? res?.data?.data[0]?.name : "");
                    setUserDetails({ ...res?.data?.data[0] })
                    setLoading(false);
                }
            }
        });

        GET_USER_SHIPPING_ADDRESS({ user_uuid: params?.user_uuid }).then((res: any) => {
            if (res?.status === "fail") {
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                setLoading(false);
            } else {
                if (res) {
                    if (res?.data?.data.length > 0) {
                        // setIsShippingAddressExist(true);
                        setShippingAddressData({ ...res?.data?.data[0] });
                    }
                    setLoading(false);
                }
            }
        });
    }

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const imageData: any = event.target.files?.[0];
        const imagesList = [];
        imagesList.push(imageData.name);
        let payload = {
            images: imagesList
        }
        GET_PRESIGNED_URL(payload).then((res: any) => {
            if (res) {
                const result = S3Helper.uploadFilesToS3BySigned(res.data?.presigned_urls[imageData.name],
                    imageData,
                    imageData.type,
                );
                // const formData = formFieldsArray;
                // const { name, value } = e.target;
                // // const field = formFieldsArray[index];
                // const { regex, label } = field;
                // const newErrors: any = { ...errors };
                // if (regex && value && !RegExp(regex).test(value)) {
                //     newErrors[name] = `Please enter a valid ${label?.toLowerCase()}`;
                // } else {
                //     delete newErrors[name];
                // }
                // formData[index].value = res.data?.presigned_urls[imageData.name].split('?')[0];
                // setFormData((prevData: any) => ({
                //     ...prevData,
                //     [name]: res.data?.presigned_urls[imageData.name].split('?')[0],
                // }));
                const file = event.target.files?.[0];
                if (file) {
                    const reader: any = new FileReader();
                    reader.onloadend = () => {
                        setImage(reader.result);
                    };
                    reader.readAsDataURL(file);
                }
                setIamgeUrl(res.data?.presigned_urls[imageData.name].split('?')[0])
                setImage(res.data?.presigned_urls[imageData.name].split('?')[0])

                // setFormFieldsArray([...formData]);
                // setErrors(newErrors);
            }
        })
        // if (file) {
        //     const reader = new FileReader();
        //     reader.onload = (e: ProgressEvent<FileReader>) => {
        //         if (e.target) {
        //             setImage(e.target.result as string);
        //         }
        //     };
        //     reader.readAsDataURL(file);
        // }
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setUserDetails((prevData: UserInfo) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateSpecificField(name, value),
        }));
    }

    const validateSpecificField = (name: string, value: string) => {
        const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{2,6}$/;
        const mobileRegEx = /^(\+1\s?)?(\([1-9][0-9]{2}\)|[1-9][0-9]{2})[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/;
        if (name === "email") {
            if (!value) {
                return "Please enter email"
            } else if (!emailRegEx.test(value)) {
                return "Please enter a valid email address";
            }
        }
        if (name === "mobile_number") {
            if (!value) {
                return "Please enter mobile phone"
            } else if (!mobileRegEx.test(value)) {
                return "Please enter a valid mobile phone";
            }
        }
        return "";
    }
    const onUpdate = () => {
        const isValid = validateForm();
        const isAddressValid = validateAddressForm();

        if (isValid && isAddressValid) {
            const formData = userDetails;
            if (formData) {
                formData.metadata = { address: formData.address }
                formData.profile_image = imageUrl ? imageUrl : formData?.profile_image
                delete formData['email']
                delete formData['facebook_app_id']
                delete formData['dob']
                delete formData['gender']
                delete formData['firebase_id']
                delete formData['password']
                delete formData['modified_dt'];
                delete formData['created_dt'];
                delete formData['id'];
                delete formData['uuid'];
                delete formData['status'];
            }

            setLoading(true);
            UPDATE_USER(params?.user_uuid || '', formData).then((res: any) => {
                if (res?.status === "fail") {
                    setLoading(false);
                    toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
                } else {
                    if (res) {
                        setLoading(false);
                        getUserInfo();
                        toast.success(res?.message, { position: "top-right" });
                    }
                }
            });
            onContinue();
        }
    }

    const validateForm = () => {
        const errors: DynamicObject = {};
        if (!userDetails?.name) {
            errors.name = "Please enter name"
        }
        if (!userDetails?.email) {
            errors.email = "Please enter email"
        }
        if (!userDetails?.mobile_number) {
            errors.mobile_number = "Please enter mobile phone"
        }

        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const handleAddressInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setShippingAddressData((prevData: ShippingAddressForm) => ({
            ...prevData,
            [name]: value,
        }));

        setFormErrors((prevErrors: DynamicObject) => ({
            ...prevErrors,
            [name]: validateAddressSpecificField(name, value),
        }));
    }

    const validateAddressSpecificField = (name: string, value: string) => {
        const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-z]{3,16}.[a-z]{2,6}$/;
        const mobileRegEx = /^(\+1\s?)?(\([1-9][0-9]{2}\)|[1-9][0-9]{2})[-.\s]?[0-9]{3}[-.\s]?[0-9]{4}$/;
        if (name === "email") {
            if (!value) {
                return "Please enter email"
            } else if (!emailRegEx.test(value)) {
                return "Please enter a valid email address";
            }
        }
        if (name === "phone_no") {
            if (!value) {
                return "Please enter mobile phone"
            } else if (!mobileRegEx.test(value)) {
                return "Please enter a valid mobile phone";
            }
        }
        return "";
    }

    const validateAddressForm = () => {
        const errors: DynamicObject = {};
        if (!shippingAddressData?.street_address) {
            errors.street_address = "Please enter street address"
        }

        if (!shippingAddressData?.unit) {
            errors.unit = "Please enter apt / unit / suite"
        }

        if (!shippingAddressData?.city) {
            errors.city = "Please enter city"
        }

        if (!shippingAddressData?.state) {
            errors.state = "Please enter state"
        }
        if (!shippingAddressData?.zip_code) {
            errors.zip_code = "Please enter zip code"
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onContinue = () => {
        setLoading(true);
        const address = shippingAddressData
        const uuid = shippingAddressData?.uuid
        address.user_uuid = params?.user_uuid;
        address.user_vehicle_uuid = params?.vehicle_uuid;

        delete address['created_dt'];
        delete address['updated_at'];
        delete address['uuid'];
        UPDATE_USER_SHIPPING_ADDRESS(uuid ? uuid : '', address).then((res: ShippingAddressResponse) => {
            if (res?.status === "fail") {
                setLoading(false);
                toast.error(res?.error?.data?.message ? res?.error?.data?.message : res?.error?.data ? res?.error?.data : res?.message, { position: "top-right" })
            } else {
                if (res) {
                    setLoading(false);
                }
            }
        });
    }
    return (
        <div className='container-fluid'>
            <Loader LoadingReducer={loading} />
            <div className='row my-3'>
                <div className='col-md-12 px-lg-5 mx-auto'>
                    <h4 className='fw-bold font-Oswald my-3 fs-40 ls-1 mb-4'>MY PROFILE</h4>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-4 rounded-3 mx-auto d-flex flex-column justify-content-center align-items-center'>
                    <img src={image} className='profile-image' alt='Profile' />
                    <input
                        type='file'
                        ref={fileInputRef}
                        name='profile-image'
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={(e) => handleFileChange(e)}
                    />
                    <button
                        className='mt-3 p-1 px-3 rounded border-0 primary-bg primary-button font-Oswald'
                        onClick={handleButtonClick}
                    >
                        Upload
                    </button>
                </div>
                <div className='col-md-8 d-flex justify-content-center align-items-center'>
                    <div className='row'>
                        <div className='col-md-6 my-2'>
                            <label className='form-label fw-bold'>Name</label>
                            <input type="text" className='form-control' placeholder='Please enter Name' name="name" defaultValue={userDetails?.name} onChange={(e) => handleInput(e)} />
                            {formErrors.name && (
                                <div className="text-danger text-start w-100">{formErrors.name} </div>
                            )}
                        </div>
                        <div className='col-md-6 my-2'>
                            <label className='form-label fw-bold'>Email</label>
                            <input type="text" className='form-control' placeholder='Please enter email' name='email' defaultValue={userDetails?.email} onChange={(e) => handleInput(e)} />
                            {formErrors.email && (
                                <div className="text-danger text-start w-100">{formErrors.email} </div>
                            )}
                        </div>
                        <div className='col-md-6 my-2'>
                            <label className='form-label fw-bold'>Mobile Number</label>
                            <input type="text" maxLength={10} className='form-control' placeholder='Please enter mobile number' name='mobile_number' defaultValue={userDetails?.mobile_number} onChange={(e) => handleInput(e)} />
                            {formErrors.mobile_number && (
                                <div className="text-danger text-start w-100">{formErrors.mobile_number} </div>
                            )}
                        </div>
                        {/* <div className='col-md-6 my-2'>
                            <label className='form-label'>Address</label>
                            <textarea className='form-control' name="address" defaultValue={userDetails?.address} placeholder='Please enter address' onChange={(e) => handleInput(e)}></textarea>
                        </div> */}

                        <div className='col-md-12 my-1 mb-2 mb-lg-1'>
                            <label className='form-label fw-bold'>Company Name (optional)</label>
                            <input type="text" name="company_name" className='form-control custom-input' defaultValue={shippingAddressData?.company_name} onChange={(e) => handleAddressInput(e)} />
                            {formErrors.company_name && (
                                <div className="text-danger text-start w-100">{formErrors.company_name} </div>
                            )}
                        </div>
                        <div className='col-md-6 my-1 pe-xl-0 mb-2 mb-lg-1'>
                            <label className='form-label fw-bold'>Street Address * </label>
                            <input type="text" name="street_address" className='form-control custom-input' defaultValue={shippingAddressData?.street_address} onChange={(e) => handleAddressInput(e)} />
                            {formErrors.street_address && (
                                <div className="text-danger text-start w-100">{formErrors.street_address} </div>
                            )}
                        </div>
                        <div className='col-md-6 my-1 mb-2 mb-lg-1'>
                            <label className='form-label fw-bold'>Apt / Unit / Suite * </label>
                            <input type="text" name="unit" className='form-control custom-input' defaultValue={shippingAddressData?.unit} onChange={(e) => handleAddressInput(e)} />
                            {formErrors.unit && (
                                <div className="text-danger text-start w-100">{formErrors.unit} </div>
                            )}
                        </div>
                        <div className='col-md-12 my-1 mb-2 mb-lg-1'>
                            <label className='form-label fw-bold'>City *</label>
                            <input type="text" name="city" className='form-control custom-input' defaultValue={shippingAddressData?.city} onChange={(e) => handleAddressInput(e)} />
                            {formErrors.city && (
                                <div className="text-danger text-start w-100">{formErrors.city} </div>
                            )}
                        </div>
                        <div className='col-md-6 my-1 pe-xl-0 mb-2 mb-lg-1'>
                            <label className='form-label fw-bold'>State * </label>
                            <select name="state" className='form-control custom-input' value={shippingAddressData?.state} onChange={(e) => handleAddressInput(e)} >
                                <option value="" disabled>Select Sate</option>
                                {Sates?.map((data: any) => { return <option value={data?.id}>{data?.text}</option> })}
                            </select>
                            {/* <input type="text" name="state" className='form-control custom-input' defaultValue={shippingAddressData?.state} onChange={(e) => handleInput(e)} /> */}
                            {formErrors.state && (
                                <div className="text-danger text-start w-100">{formErrors.state} </div>
                            )}
                        </div>
                        <div className='col-md-6 my-1 mb-2 mb-lg-1'>
                            <label className='form-label fw-bold'>ZIP Code *</label>
                            <input type="text" name="zip_code" maxLength={6} className='form-control custom-input' defaultValue={shippingAddressData?.zip_code} onChange={(e) => handleAddressInput(e)} />
                            {formErrors.zip_code && (
                                <div className="text-danger text-start w-100">{formErrors.zip_code} </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 text-end my-3'>
                <button className='p-1 px-4 rounded border-0 primary-bg primary-button font-Oswald' onClick={onUpdate}>Update</button>
            </div>
        </div>
    )
}
