import React, { useEffect, useRef, useState } from "react";
import simpleTag from './../../assets/images/logo.svg'
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ProfileImage from './../../assets/images/profile.avif';

interface Props {
  isLoginPage?: (data: any) => void;
}
export const HeaderPage: React.FC = (props: Props) => {
  const location = useLocation();
  const navigation = useNavigate();
  const userId = sessionStorage.getItem("user_id");
  const userName = sessionStorage.getItem('user_name');
  const profile_image = sessionStorage?.getItem('profile-image');
  const params = useParams();
  const user_uuid = sessionStorage.getItem('user_id');
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(true);
  const navbarRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
  }, [])

  const onLogout = () => {
    navigation("/");
    sessionStorage.clear();
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
      setIsNavbarCollapsed(true);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const onLogin = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
    const loginStatus: any = true;
    sessionStorage.setItem('login-status', loginStatus);
    if (props?.isLoginPage) {
      props?.isLoginPage(true);
    }
    navigation("/login")
  }

  return (
    <div className="col-md-12 bg-white header-position">
      <nav className="navbar navbar-expand-md bg-white">
        <div className="container px-xl-5 d-block">
          <div className="col-md-12 px-xl-2">
            <div className="d-flex justify-content-between">
              <div className="d-flex justify-content-between w-100">
                <div>
                  <NavLink to="/"><a className="navbar-brand">
                    <img src={simpleTag} className="header-logo" />
                  </a>
                  </NavLink>
                </div>
                {!userId && (<div >
                  {/* <button className="hamburger-icon navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button> */}
                  <button
                    className="hamburger-icon navbar-toggler"
                    type="button"
                    onClick={toggleNavbar}
                    aria-controls="navbarTogglerDemo02"
                    aria-expanded={!isNavbarCollapsed}
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>)}
                {userId && (<div>
                  <div className="ms-auto d-md-none">
                    <ul className="navbar-nav ms-auto menu_mobile text-end w-100 mb-2 mb-lg-0">
                      <li className="nav-item dropdown cursor-pointer font-bebas">
                        <a className="nav-link active" aria-current="page" id="navbarDropdown3" data-bs-toggle="" aria-expanded="false">
                          <img src={profile_image ? profile_image : ProfileImage} className="header-profile-image" />
                        </a>
                        <ul className="dropdown-menu ms-auto mobile-drop-down shadow py-0" aria-labelledby="navbarDropdown3">
                          <li className="border-bottom px-1 py-2 font-Oswald"><a className="dropdown-item text-primary fw-bold">Hello! {userName ? userName : "User"}</a></li>
                          <NavLink to={`/dashboard/profile/${user_uuid}`} className="text-decoration-none"><li className="border-bottom primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item">Profile</a></li></NavLink>
                          <li className="border-bottom primary-bg px-4 py-2 font-Oswald" onClick={onLogout}><a className="dropdown-item">Logout</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>)}
              </div>

              <div className={`collapse navbar-collapse menu_mobile text-end w-100 ${isNavbarCollapsed ? '' : 'show'}`} id="navbarTogglerDemo02" ref={navbarRef}>
                {!userId && (
                  <ul className="navbar-nav ms-auto mb-2 mobile_hamburger mobile_shadow mb-lg-0 bg_white rounded text-start">
                    <li className="nav-item dropdown cursor-pointer font-bebas d-block mt-2">
                      <a className="nav-link py-0 active" aria-current="page" id="navbarDropdown1" role="button" data-bs-toggle="" aria-expanded="false">
                        ONLINE DMV SERVICES
                      </a>
                      <ul className="dropdown-menu online-service-drop-down online-service-mobile-drop-down py-0" aria-labelledby="navbarDropdown1">
                        <li className="border-bottom px-4 py-2 font-Oswald"><a className="dropdown-item">Renew Vehicle Registration</a></li>
                        <li className="border-bottom primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item">Replace Vehicle Registration Sticker/Card</a></li>
                        <li className="border-bottom primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item">Transfer title</a></li>
                        <li className="border-bottom primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item">Replace title</a></li>
                        <li className="border-bottom primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item">Boat-Renew Registration</a></li>
                        <li className="border-bottom primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item">Boat-Renew Registration Sticker/Card</a></li>
                        <li className="border-bottom primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item">Boat-Transfer title</a></li>
                        <li className="border-bottom primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item">Boat-Replace title</a></li>
                        <li className="primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item">Check Registration Status</a></li>
                      </ul>
                    </li>
                    <li className="nav-item dropdown cursor-pointer font-bebas ms-xl-2 mt-2">
                      <a className="nav-link py-0" aria-current="page" id="navbarDropdown2" role="button" data-bs-toggle="" aria-expanded="false">
                        SUPPORT
                      </a>
                      <ul className="dropdown-menu support-drop-down py-0 mb-3 mb-md-0" aria-labelledby="navbarDropdown2">
                        <li className="border-bottom px-4 py-2 font-Oswald"><a className="dropdown-item">Contact Customer Support</a></li>
                        <NavLink className="text-decoration-none" to="/order-status"><li className="border-bottom primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item text-decoration-none">Check Order Status</a></li></NavLink>
                      </ul>
                    </li>
                    <li className="nav-item mobile-login " onClick={() => onLogin()}>
                      <a className="nav-link text-decoration-none rounded-1 px-3 px-md-3 ms-md-3 primary-bg cursor-pointer font-Oswald fw-400 py-1 text-dark border border-1 border-dark text-dark" type="button" >
                        LOG IN
                      </a>
                    </li>
                  </ul>
                )}
                {userId && (
                  <div className="d-none d-md-block ms-auto">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                      <li className="nav-item dropdown cursor-pointer font-bebas">
                        <a className="nav-link active" aria-current="page" id="navbarDropdown3" data-bs-toggle="" aria-expanded="false">
                          <img src={profile_image ? profile_image :ProfileImage} className="header-profile-image" style={{borderRadius:"50%"}} />
                        </a>
                        <ul className="dropdown-menu online-service-drop-down shadow py-0" aria-labelledby="navbarDropdown3">
                          <li className="border-bottom px-1 py-2 font-Oswald"><a className="dropdown-item text-primary fw-bold">Hello! {userName ? userName : "User"}</a></li>
                          <NavLink to={`/dashboard/profile/${user_uuid}`} className="text-decoration-none"><li className="border-bottom primary-bg px-4 py-2 font-Oswald"><a className="dropdown-item">Profile</a></li></NavLink>
                          <li className="border-bottom primary-bg px-4 py-2 font-Oswald" onClick={onLogout}><a className="dropdown-item">Logout</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12 px-4 px-lg-4 my-md-0 fs-17 fs-m-20 font-Oswald header-logo-caption">
              DMV Delegated Provider-Licensed service provider by the CA Department of Motor Vehicles
            </div>
          </div>
        </div >
      </nav >
    </div >
  );
};
