// export const BASE_URL = "http://localhost:7799/";
// export const BASE_URL = "http://192.168.1.52:7799/";
// export const BASE_URL = "http://54.85.190.77:7799/";
export const BASE_URL = "http://54.245.234.128:9100/";

/**Payment Details */
export const PAYMENT_URL = "https://apimpdev.memberplanet.com/api/ews/v2/";
export const ClientId = "8f9184af-5d1b-11ec-9d26-026ebcd31737";
export const ClientSecret = "489C964AB3FC11E990CB02AF2480A099";

/**USPS Address */
export const USPS_URL = "https://api.usps.com/";
export const USPS_ClientId = "3hI6MRbQFirEGacvrSHZIGwnLy4RagMa";
export const USPS_ClientSecret = "CteqLP44LArWM1QA";

const appConfig = {
  BASE_URL: BASE_URL,
  PAYMENT_URL: PAYMENT_URL,
  ClientId: ClientId,
  ClientSecret: ClientSecret,
  USPS_URL: PAYMENT_URL,
  USPS_ClientId: ClientId,
  USPS_ClientSecret: ClientSecret,
};
export default appConfig;
